<template>
  <v-dialog
    v-model="isVisibleEditPackageItemDialog"
    max-width="75%"
    persistent
  >
    <template>
      <v-card class="rounded-lg">
        <!-- selected item -->
        <div
          class="d-flex align-center justify-space-between px-6 py-6"
          style="width: 100%"
        >
          <h3 class="font-weight-regular">
            {{ $t("setup.grouped_products.select_pack_item") }}
          </h3>
          <v-spacer></v-spacer>
          <div>
            <v-icon @click="closeForm">mdi-close</v-icon>
          </div>
        </div>
        <!-- selected item end -->
        <div class="pa-6">
          <h3 class="mb-4">
            {{ $t("products_list") }}
          </h3>
          <v-row>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                outlined
                dense
                hide-details
                class="mb-6"
                v-model="options.search"
                :label="$t('search')"
                :disabled="is_loading_product"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-autocomplete
                outlined
                dense
                hide-details
                class="mb-6"
                clearable
                v-model="options.category_id"
                :items="leafCategories"
                item-text="name"
                item-value="id"
                :loading="isLoadingCategories"
                :label="$t('category')"
                :disabled="is_loading_product"
                @focus="getSubCategories"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-text-field
                outlined
                dense
                hide-details
                type="number"
                hide-spin-buttons
                class="mb-6"
                v-model="options.qte"
                :label="$t('stock_volume')"
                :disabled="is_loading_product"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="listProducts"
            :loading="isLoadingProducts"
            :server-items-length="productsMeta.totalItems"
            :options.sync="options"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [5, 10, 20],
              'disable-pagination': isLoadingProducts,
            }"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                @click="toggleSelectedProduct(item)"
                small
                color="primary"
                v-if="isItemSelected(item)"
              >
                {{ $t("global.actions.selected") }}
              </v-btn>
              <v-btn
                @click="toggleSelectedProduct(item)"
                small
                outlined
                v-if="!isItemSelected(item)"
              >
                {{ $t("global.actions.select") }}
              </v-btn>
            </template>
          </v-data-table>
        </div>
        <template v-if="selectedItem?.id">
          <div
            class="d-flex justify-end mb-8 me-4"
            style="min-width: 600px"
          >
            <div>
              <div class="mb-4">
                <h3 class="ms-4">
                  {{ $t("setup.grouped_products.selected_product") }}
                </h3>
                <v-divider class="my-0"></v-divider>
              </div>
              <div class="d-flex align-center">
                <v-img
                  v-if="selectedItem?.images.length > 0"
                  :src="selectedItem?.images[0]?.url"
                  max-height="100"
                  max-width="100"
                />
                <v-img
                  v-else
                  src="@/assets/product.png"
                  max-height="50"
                  max-width="50"
                />
                <div class="me-4 pt-4">
                  <h4>{{ selectedItem?.name }}</h4>
                  <p>
                    ID:&nbsp;{{ selectedItem?.code }} -
                    {{ $t("barcode") + ": " + selectedItem?.barcode }}
                  </p>
                </div>
                <v-text-field
                  class="flex-grow-0"
                  outlined
                  dense
                  hide-details
                  type="number"
                  hide-spin-buttons
                  :label="$t('quantity')"
                  v-model="selectedItem.qte_in_bundle"
                  :rules="[]"
                >
                  <template v-slot:append-outer>
                    <v-icon
                      color="red"
                      @click="toggleSelectedProduct(selectedItem)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="addPackItem"
            medium
            filled
            color="primary"
            :disabled="!selectedItem?.qte_in_bundle"
          >
            {{ $t("setup.grouped_products.select_item_for_package") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { helpers } from "@/mixins/helpers";
import debounce from "lodash/debounce";

export default {
  props: {
    updatePrices: Function,
  },
  mixins: [helpers],
  computed: {
    ...mapGetters({
      leafCategories: "categories/listLeafs",
      isLoadingCategories: "categories/isLoadingCategories",
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
      selectedCategory: "packsAndBundles/selectedCategory",
    }),

    isVisibleEditPackageItemDialog: {
      get() {
        return this.$store.state.packsAndBundles.isVisibleEditPackageItemDialog;
      },
      set(value) {
        this.$store.commit(
          "packsAndBundles/IS_VISIBLE_EDIT_PACKAGE_ITEM_DIALOG",
          value
        );
      },
    },
    editedPackItem: {
      get() {
        return this.$store.state.packsAndBundles.editedPackItem;
      },
      set(value) {
        this.$store.dispatch("packsAndBundles/selectPackItem", value);
      },
    },
  },
  watch: {
    options: {
      deep: true,
      handler: debounce(async function () {
        this.is_loading_product = true;
        await this.$store.dispatch("products/root", this.options).then(() => {
          this.is_loading_product = false;
        });
      }, 1000),
    },
    isVisibleEditPackageItemDialog(value) {
      if (!value) {
        this.selectedItem = { ...this.editedPackItem };
      }
    },
    selectedCategory(value) {
      this.options.category_id = value;
    },
    editedPackItem(value) {
      if (value?.id !== this.selectedItem?.id) {
        this.selectedItem = { ...this.editedPackItem };
      }
    },
  },
  data() {
    return {
      search: "",
      options: { itemsPerPage: 10, qte: null },
      is_loading_product: false,
      selectedItem: { ...this.editedPackItem },

      headers: [
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },
        {
          text: this.$t("product_name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("product_id"),
          align: "start",
          sortable: true,
          value: "code",
        },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: true,
          value: "sku",
        },
        {
          text: this.$t("price"),
          value: "price",
          sortable: false,
        },
        {
          text: this.$t("stock"),
          value: "qte",
        },

        {
          text: this.$t("moving"),
          value: "qte",
          sortable: true,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    closeForm() {
      this.$store.dispatch("packsAndBundles/closeForm");
    },
    toggleSelectedProduct(product) {
      this.selectedItem?.id === product.id
        ? (this.selectedItem = {})
        : (this.selectedItem = { ...product });
    },
    isItemSelected(item) {
      return item?.id === this.selectedItem?.id;
    },
    async addPackItem() {
      await this.$store.dispatch(
        "packsAndBundles/selectPackItem",
        this.selectedItem
      );
      this.updatePrices();
    },
  },
  mounted() {
    this.selectedItem = { ...this.editedPackItem };
    this.options.category_id = this.selectedCategory;
  },
};
</script>